@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titan+One&display=swap');

@font-face {
  font-family: 'PPMori';
  src: local('PPMori'),
    url('/src/assets/fonts/PPMori-SemiBold.otf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'PPMori';
  src: local('PPMori'),
    url('/src/assets/fonts/PPMori-Regular.otf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'PPMori';
  src: local('PPMori'),
    url('/src/assets/fonts/PPMori-Extralight.otf') format('truetype');
  font-weight: 100;
}

body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
  /* min-height: 100vh; */
  overflow-x: hidden;
}

html {
  scroll-padding-top: 100px;
  width: 100%;
}

* {
  font-family: 'PPMori', sans-serif;
  box-sizing: border-box;
}

a {
  text-decoration: unset;
}

.page {
  min-height: 100%;
  -webkit-overflow-scrolling: touch; /* iPhone smooth scroll */
  scroll-behavior: smooth;
  position: relative;
}

.body {
  max-width: 100%;
  height: 100%;
  min-height: calc(100vh - 0px);
  background-color: #22252d;
  font-family: 'PPMori';
  font-weight: 400;
  font-style: normal;
}

.collabland {
  margin-bottom: 10px;
  flex-shrink: 0;
  margin: 0 auto;
}

.collabland h1 {
  font-family: 'PPMori', sans-serif;
  max-width: 1400px;
  font-size: 4em;
  line-height: 4rem;
  margin: 0.8em 0 0.5em 0;
  letter-spacing: 0.03em;
  width: 100%;
  text-align: center;
  font-weight: 900;
}

.collabland h2 {
  font-family: 'PPMori', sans-serif;
  font-size: 1.75rem;
  color: #333c46;
  font-weight: 500;
  text-align: center;
  margin-top: 3.2rem;
  margin-bottom: 18px;
  font-weight: 900;
}

.collabland h3 {
  font-size: 1.47rem;
  font-weight: 700;
  color: #333c46;
  text-align: left;
  margin-top: 40px;
  margin-bottom: 0;
  letter-spacing: 0.01em;
}

.collabland h4 {
  font-size: 1.125rem;
  font-weight: bold;
  color: hsl(213, 28%, 19%);
  text-align: left;
  margin-top: 30px;
  margin-bottom: 0;
}

.collabland div,
span,
a,
button,
section {
  font-family: 'PPMori', sans-serif;
}
@media (max-width: 1600px) {
  .collabland h1 {
    font-size: 4rem;
    line-height: 4rem;
  }
}

@media (max-width: 1400px) {
  .collabland h1 {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }
}

@media (max-width: 1280px) {
  .collabland h1 {
    font-size: 3rem;
    line-height: 3rem;
  }
}

@media (max-width: 1024px) {
  .collabland h1 {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
}
@media (max-width: 768px) {
  .collabland h1 {
    font-size: 2.5rem;
    line-height: 2.3rem;
  }
}

@media (max-width: 640px) {
  .collabland h1 {
    font-size: 2.5rem;
    line-height: 2.2rem;
  }
  .collabland h2 {
    font-size: 1.55rem;
  }
  .collabland h3 {
    font-size: 1.25rem;
  }
  .collabland h4 {
    font-size: 1rem;
  }
}
@media (max-width: 350px) {
  .collabland h1 {
    font-size: 2.5rem;
    line-height: 2.1rem;
  }
}
@media (max-width: 300px) {
  .collabland h1 {
    font-size: 2.2rem;
    line-height: 2.1rem;
  }
}

.collabland p {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.55;
  color: #696969;
  white-space: normal;
  margin: 1rem 0;
}

.collabland b {
  font-weight: 600;
}

.collabland li {
  font-size: 16px;
  line-height: 1.48;
  color: hsl(218, 25%, 25%);
  margin: 5px;
}

.collabland table {
  margin: 20px auto;
  border-collapse: collapse;
}

.collabland .operator {
  max-width: 25px;
  font-size: 18px;
}

.collabland td.money {
  text-align: right;
}

.collabland td.dollar {
  padding-left: 10px;
}

.collabland tr.total {
  border-top: 1px solid #888;
}

.collabland tr.total th {
  font-weight: 500;
}

.collabland tr.total td {
  font-size: 18px;
}

@media (max-width: 700px) {
  .collabland p {
    font-size: 15px;
  }

  .collabland ul {
    padding-left: 20px;
    margin: 10px 0;
  }

  .collabland li {
    font-size: 14px;
  }

  .collabland tr.total td {
    font-size: 16px;
  }

  .collabland .operator {
    font-size: 16px;
  }

  .collabland td.dollar {
    padding-left: 6px;
  }

  .collabland td.money {
    min-width: 0px;
  }
}

@media (min-width: 701px) {
  .collabland .mobile {
    display: none !important;
  }
}

@media (max-width: 700px) {
  .collabland .desktop {
    display: none !important;
  }
}

@media (max-width: 640px) {
  .collabland {
    width: inherit;
  }
}

/* custom checkbox */
.checkBoxContainer {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkBoxContainer input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.checkmarkScope {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: #00b167;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  /* border-radius: 10px; */
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkBoxContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkBoxContainer input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* When the checkbox is disabled, add a blue background */
/* .checkBoxContainer input:disabled ~ .checkmark {
  background-color: #929598;
} */

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkBoxContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkBoxContainer .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmarkScope:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkBoxContainer input:checked ~ .checkmarkScope:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkBoxContainer .checkmarkScope:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.authBoxShadow {
  box-shadow: 3px 3px 10px #1a1a1a;
}

.introLink {
  text-decoration: underline;
  font-size: 18px;
  cursor: pointer;
  color: #fff;
}

.introLink:hover {
  color: #e6e6e6;
}
