@tailwind base;
@tailwind components;
@tailwind utilities;

.new-index-bg {
  background-image: url('/src/assets/footer-bg.png');
  background-size: cover;
}

.new-navbar-container {
  background-color: transparent;
}
.new-navbar {
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 1px 6px 0px rgb(55, 65, 81, 0.5);
}

.info {
  text-shadow: 3px 0px 12px #a0cdff;
}

.new-button {
  background-color: #f6c349; /* #1F0061 */
  border: 2px solid #f6c349;
  border-radius: 8px;
  color: #111827; /* convert from rgb(17 24 39);*/
}
.new-button:hover {
  background-color: #f8b210;
  border: 2px solid #f8b210;
}
.new-button-disabled {
  background-color: #a3a3a3;
  border: 2px solid #a3a3a3;
  border-radius: 8px;
  color: #fff;
}
.new-button-disabled:hover {
  cursor: not-allowed;
}
.new-button-secondary {
  background-color: #fff;
  border: 2px solid #f6c349;
  border-radius: 8px;
  color: #111827;
}
.new-button-secondary:hover {
  background-color: #f8b210;
  color: #fff;
}
.new-button-secondary-disabled {
  background-color: #fff;
  border: 2px solid #a3a3a3;
  border-radius: 8px;
  color: #a3a3a3;
}
.new-button-secondary-disabled:hover {
  background-color: #fff;
  cursor: not-allowed;
}
.new-danger-button {
  background-color: #ff594f; /* #1F0061 */
  border: 2px solid #ff594f;
  border-radius: 8px;
  color: #fff;
}
.new-danger-button:hover {
  background-color: #f73328;
  border: 2px solid #f73328;
}

@media only screen and (min-width: 1280px) {
  .new-button,
  .new-button-disabled,
  .new-button-secondary,
  .new-button-secondary-disabled,
  .new-danger-button {
    font-size: 16px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .new-button,
  .new-button-disabled,
  .new-button-secondary,
  .new-button-secondary-disabled,
  .new-danger-button {
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .new-button,
  .new-button-disabled,
  .new-button-secondary,
  .new-button-secondary-disabled,
  .new-danger-button {
    font-size: 12px;
  }
}
